<template>
  <div>
    <a-row :gutter="24" type="flex">
      <!-- Projects Table Column -->
      <a-col :span="24" class="mb-24">
        <a-card
          :bordered="false"
          class="header-solid h-full"
          :bodyStyle="{ padding: 0 }"
        >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12" class="top-left-header">
                <h5 class="font-semibold m-0">
                  Personal Questions<a-spin v-if="confirmLoading" />
                </h5>
              </a-col>
              <a-col :span="24" :md="12" class="top-right-header">
                <a-button>
                  <export-excel
                    :data="jsonPersonalQuestionData"
                    :fields="jsonFields"
                    worksheet="My Worksheet"
                    :name="'personal_question_list.xls'"
                  >
                    Export
                  </export-excel>
                </a-button>
                <a-range-picker
                  v-model:dateRange="dateRange"
                  format="YYYY-MM-DD"
                  @change="onDateChange"
                />
                <a-radio-group
                  v-model="projectHeaderBtns"
                  size="small"
                  @change="onStatusChange"
                >
                  <a-radio-button value="all">ALL</a-radio-button>
                  <a-radio-button value="approve">APPROVE</a-radio-button>
                  <a-radio-button value="pending">PENDING</a-radio-button>
                </a-radio-group>
              </a-col>
            </a-row>
          </template>
          <a-table
            :columns="columnData"
            :data-source="personalQuestionList"
            :pagination="false"
          >
            <!-- <template slot="name" slot-scope="text">
              <a>{{ text }}</a>
            </template> -->

            <template slot="detailsBtn" slot-scope="row">
              <div>
                <a-button type="link" :data-id="row.key">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="row.status === 'approve'"
                    @click="showUploadModal(row.details)"
                  >
                    <path
                      class="fill-gray-7"
                      d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
                    />
                    <path
                      class="fill-gray-7"
                      d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    @click="showUploadModal(row.details)"
                    v-else
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z"
                      fill="#111827"
                    />
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    @click="showDetails(row.details)"
                  >
                    <path
                      d="M22 24h-20v-24h14l6 6v18zm-7-23h-12v22h18v-16h-6v-6zm3 15v1h-12v-1h12zm0-3v1h-12v-1h12zm0-3v1h-12v-1h12zm-2-4h4.586l-4.586-4.586v4.586z"
                    />
                  </svg>
                </a-button>
              </div>
            </template>
          </a-table>

          <div class="pagination">
            <a-pagination
              v-model:current="currentPage"
              :total="pagination.total"
              :page-size="20"
              :hideOnSinglePage="true"
              @change="onPageChange"
            />
          </div>
        </a-card>
      </a-col>
      <!-- / Projects Table Column -->

      <!--    Details Modal-->
      <a-modal
        v-if="Object.keys(questionDetails).length"
        v-model:detailsModal="detailsModal"
        title="Personal Question Details"
        @ok="closeDetailsModal"
      >
        <div class="details">
          <div>
            User ID: <span>{{ questionDetails.user_id }}</span>
          </div>
          <div>
            User Name: <span>{{ questionDetails.name }}</span>
          </div>
          <div>
            User Gender: <span>{{ questionDetails.gender }}</span>
          </div>
          <div>
            Age: <span>{{ questionDetails.age }}</span>
          </div>

          <div>
            Status: <span>{{ questionDetails.status }}</span>
          </div>
          <div>
            Question: <span>{{ questionDetails.question }}</span>
          </div>
          <div>Answer: <span v-html="questionDetails.answer"></span></div>
          <div>
            Created At: <span>{{ questionDetails.created_date }}</span>
          </div>
        </div>
      </a-modal>

      <!--    New Upload Or Update Data-->
      <a-modal
        v-model:uploadModal="uploadModal"
        title="Personal Question Upload Answer"
        :confirm-loading="confirmLoading"
        @ok="closeUploadModal"
      >
        <div class="upload-data">
          <div>
            <span style="font-weight: 800">Question ID:</span>
            {{ selectedQuestionId }}
          </div>
        </div>
        <a-row style="margin-top: 6px">
          <a-col :span="24">
            <div style="margin-bottom: 5px; font-weight: 800">Answer</div>
          </a-col>
          <a-col :span="24">
            <vue-editor
              v-model="questionAnswer"
              placeholder="question answer"
            />
          </a-col>
        </a-row>
      </a-modal>
    </a-row>
  </div>
</template>

<script>
import moment from "moment/moment";
import { sendRequest } from "@/http/axios.method";
import { VueEditor } from "vue2-editor";

const COLUMN_DATA = [
  {
    title: "ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    width: 90,
  },
  {
    title: "USER-ID",
    dataIndex: "user_id",
    scopedSlots: { customRender: "user_id" },
    width: 100,
  },
  {
    title: "NAME",
    dataIndex: "name",
    class: "font-semibold text-muted",
  },
  {
    title: "AGE",
    dataIndex: "age",
    class: "font-semibold text-muted",
  },
  {
    title: "QUESTION",
    dataIndex: "question",
    class: "font-semibold text-muted",
  },
  {
    title: "DATE",
    dataIndex: "created_date",
    class: "font-semibold text-muted",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    class: "font-semibold text-muted text-sm",
  },
  {
    title: "",
    scopedSlots: { customRender: "detailsBtn" },
    width: 200,
  },
];

const JSON_FIELD = {
  "Question ID": "id",
  "User ID": "user_id",
  "User Name": "name",
  "User Gender": "gender",
  "User Age": "age",
  "User Question": "question",
  "Question Status": "status",
  "Created At": "created_date",
};

const JSON_META = [
  [
    {
      key: "charset",
      value: "utf-8",
    },
  ],
];
export default {
  name: "PersonalQuestion",
  components: {
    VueEditor,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columnData: COLUMN_DATA,
      personalQuestionData: [],
      personalQuestions: [],
      currentPage: 1,
      pagination: {},
      projectHeaderBtns: "all",
      modalOpen: false,
      startDate: "",
      endDate: "",
      dateRange: [],
      detailsModal: false,
      questionDetails: [],
      uploadModal: false,
      selectedQuestionId: "",
      questionAnswer: "",
      confirmLoading: false,
      jsonFields: JSON_FIELD,
      jsonPersonalQuestionData: [],
      json_meta: JSON_META,
    };
  },
  created() {
    this.getPersonalQuestionList();
  },
  methods: {
    onDateChange() {
      this.startDate = "";
      this.endDate = "";

      if (Object.keys(this.dateRange).length) {
        this.startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
        this.endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      }

      this.getPersonalQuestionList();
    },
    onPageChange() {
      this.getPersonalQuestionList();
    },
    onStatusChange() {
      this.getPersonalQuestionList();
    },
    getPersonalQuestionList() {
      let data = {
        page: this.currentPage,
        status_type: this.projectHeaderBtns,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      this.confirmLoading = true;
      sendRequest("get", "admin/personal-questions", data, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            this.personalQuestions = response.data.personal_questions;
            this.personalQuestionData = this.personalQuestions.map(function (
              value,
              label
            ) {
              return {
                key: label + 1,
                id: value.id,
                user_id: value.user_id,
                name: value.name,
                age: value.age,
                question: value.question.substring(0, 30) + "...",
                created_date: value.created_date,
                status: value.status,
                details: value,
              };
            });
            this.pagination = response.data.meta.pagination;
            this.currentPage = this.pagination.current_page;
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    closeDetailsModal() {
      this.detailsModal = false;
      this.questionDetails = [];
    },
    showDetails(details) {
      this.detailsModal = true;
      this.questionDetails = details;
    },
    showUploadModal(question) {
      this.uploadModal = true;
      this.selectedQuestionId = question.id;
      this.questionAnswer = question.answer;
    },
    closeUploadModal() {
      this.confirmLoading = true;
      let formData = new FormData();
      let configHeader = {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + this.$store.state.authUser.token,
      };
      formData.append("question_id", this.selectedQuestionId);
      formData.append("answer", this.questionAnswer);

      sendRequest(
        "post",
        "admin/personal-questions-answer",
        formData,
        configHeader
      )
        .then((response) => {
          if (response.success) {
            this.confirmLoading = false;
            this.uploadModal = false;
            this.selectedQuestionId = "";
            this.questionAnswer = "";
            this.getPersonalQuestionList();
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    loadPersonalQuestionData(question) {
      this.jsonPersonalQuestionData.push({
        id: question.id,
        user_id: question.user_id,
        name: question.name,
        gender: question.gender,
        age: question.age,
        status: question.status,
        created_date: question.created_date,
        question: question.question,
      });
    },
  },
  computed: {
    personalQuestionList: function () {
      this.jsonPersonalQuestionData = [];
      this.personalQuestions.map((question) => {
        this.loadPersonalQuestionData(question);
      });
      return this.personalQuestionData;
    },
  },
};
</script>

<style scoped></style>
